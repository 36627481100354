import React, { useState } from 'react';  
import PdfViewer from '../components/pdfViewer';  
import '../App.css';  
import DefaultExample from '../components/DataGrid';  
import { useLocation } from 'react-router-dom'; 
import { GlobalNavExample } from "../components/NavigationBar";  
import { Button } from '@optum-osgp-temp/osgp-ui-component-lib';  
  
function CustomModelPage() {  
  const [responseData, setResponseData] = useState(null); 
  const [modelName,setModelName] = useState(''); 
  const [integrityOpen, setintegrityOpen] = useState(false);  
  const [isIntegrityFailed, setIsIntegrityFailed] = useState(false);  
  const location = useLocation();  
  const { userName } = location.state || {};  
    
  const handleResponseData = (data) => { 
    if(data){
      const jsonData = JSON.parse(data.jsonOutput);
      const model = data.customModelName;
      setResponseData(jsonData);
      setModelName(model);
    }  
  }  

  const [fileName,setFileName] = useState(null);
  const handlefileName = (value) => {
    setFileName(value);
  }
    
  const handleIntegrityFailed = (isFailed) => {  
    setIsIntegrityFailed(isFailed);  
  }  
    
  const [text, setText] = useState(null);  
  const summarization = (data) => {  
    setText(data);  
  }  
    
  const [selectedItem, setSelectedItem] = useState(null);  
  function onFieldClick(params) {  
    const rowIndex = params.row.id;  
    const selectedData = responseData[rowIndex];  
    setSelectedItem(selectedData);  
  }  
    
  const [tableselectedItem, setTableSelectedItem] = useState(null);  
  function TableFieldClick(params, arrayData) {  
    const tablerowIndex = params.row.id;  
    const tableRowData = arrayData.value.find(row => row.id === tablerowIndex);  
    if (!tableRowData) {  
      return;  
    }  
    const tableData = tableRowData.value;  
    setTableSelectedItem(tableData);  
  }  
    
  return (  
    <div style={{ marginBottom: '5px' }}>  
      <GlobalNavExample />  
      <div className='page'>  
        <div className='pdf'>  
          <PdfViewer handleFileName={handlefileName} selectedItem={selectedItem} tableselectedItem={tableselectedItem} onReceiveResponse={handleResponseData} text={text} handleIntegrityFailed={handleIntegrityFailed} integrityOpen={integrityOpen} setintegrityOpen={setintegrityOpen} model={modelName}/>  
        </div>  
        <div className='model'>  
          {!isIntegrityFailed && (  
            <DefaultExample  
              userName= {userName} 
              fileName={fileName}
              selectedItem={selectedItem}   
              data={responseData}   
              sumarizeData={summarization}   
              onFieldClick={onFieldClick}   
              TableFieldClick={TableFieldClick}   
            />  
          )}  
          {isIntegrityFailed && (  
            <div style={{ display: 'flex', justifyContent: 'center',  alignItems: 'center',  flexDirection: 'column',  height: '80vh',  width: '50%',  position: 'absolute',  right: '5%',  top: '10%',overflow: 'hidden'}}>  
              <div style={{border: '3px solid #ff5200',borderRadius: '20px', padding: '30px',display: 'flex',justifyContent: 'center',alignItems: 'center',flexDirection: 'column',backgroundColor: 'white',width: '100%',boxSizing: 'border-box'}}>  
                <p style={{ color: 'black', textAlign: 'center', marginBottom: '20px',fontWeight: 'bold' }}>  
                  "The form has been rejected due to failed integrity checks as defined by the admin. It has been marked for manual processing"  
                </p>  
                <Button variant='ghost' onPress={() => {setintegrityOpen(true);}}>Show Integrity Results</Button>
                <div style={{width: '100%',marginBottom: '20px'}}>  
                </div>  
                <div style={{border: '1px solid #ccc',borderRadius: '10px',padding: '20px',backgroundColor: '#f9f9f9',width: '100%'}}>  
                  <h4 style={{fontWeight: 'bold',marginBottom: '10px'}}>Tips for Successful Submission</h4>  
                  <ul style={{ paddingLeft: '20px' }}>  
                    <li>Ensure all fields are filled out correctly.</li>    
                    <li>Attach all necessary documents if required.</li>  
                  </ul>  
                </div>  
              </div>  
            </div>  
          )}  
        </div>  
      </div>  
    </div>  
  );  
}   
export default CustomModelPage;