import React, { useState } from 'react';  
import { DataGrid } from '@mui/x-data-grid';  
import EditIcon from '@mui/icons-material/Edit';  
import SaveIcon from '@mui/icons-material/Save';  
import TextField from '@mui/material/TextField';  
import IconButton from '@mui/material/IconButton'; 
import  "./CollapsibleTable.css"
import { Button } from '@optum-osgp-temp/osgp-ui-component-lib';
  
const CollapsibleTable = ({ arrayData, confidenceInput, TableFieldClick, renderConfidence, rowId, updateGridRow }) => {  
  const [editingRowId, setEditingRowId] = useState(null);  
  const [editingRowData, setEditingRowData] = useState({});
  const [gridKey] = useState(0);   
  const [tableRows, setTableRows] = useState(arrayData.value.map((rowItem, index) => ({  
    id: index,  
    ...rowItem.value.reduce((acc, cell) => {  
      const cellKey = cell.key.toLowerCase().replace(/ /g, '_');  
      acc[cellKey] = cell.value;  
      return acc;  
    }, {}),  
    certainty_score: rowItem.certainty_score,  
  }))); 
  
  const handleEditClick = (row) => {  
    setEditingRowId(row.id);  
    setEditingRowData(row);  
  };  
  
  const handleEditChange = (event, field) => {  
    setEditingRowData({  
      ...editingRowData,  
      [field]: event.target.value,  
    });  
  };

  const handleSave = () => {  
    if (editingRowId !== null) {  
      const updatedRows = tableRows.map((row) =>  
        row.id === editingRowId ? { ...row, ...editingRowData } : row  
      );  
      setTableRows(updatedRows);  
   
      const updatedTableData = updatedRows.map(row => ({  
        RowNumber: row.id,  
        certainty_score: row.certainty_score,  
        value: arrayData.columns.map(columnName => {  
          const cellKey = columnName.toLowerCase().replace(/ /g, '_');  
          return {  
            key: columnName,  
            value: row[cellKey],  
          };  
        })  
      }));  
     
      updateGridRow(rowId, updatedTableData);  
      setEditingRowId(null);  
      setEditingRowData({});  
    }  
  };  

  const handleAddRow = () => {  
    const newId = tableRows.length > 0 ? Math.max(...tableRows.map(r => r.id)) + 1 : 0;  
    const newRowValues = arrayData.columns.map(columnName => ({  
      key: columnName,  
      value: '', 
    }));  
    const newRow = {  
      id: newId,  
      ...newRowValues.reduce((acc, cell) => {  
        const cellKey = cell.key.toLowerCase().replace(/ /g, '_');  
        acc[cellKey] = cell.value;  
        return acc;  
      }, {}),  
      certainty_score: '',  
    };  
    const updatedRows = [...tableRows, newRow];  

    setTableRows(updatedRows);  
    setEditingRowId(newId); 
    setEditingRowData(newRow); 
  };  
     
  const handleCellClick = (params) => {  
    if (!editingRowId && params.field !== '__check__') {  
      TableFieldClick(params, arrayData);  
    }  
  }; 
   
  const columns = arrayData.columns.map((columnName) => ({  
    field: columnName.toLowerCase().replace(/ /g, '_'),  
    headerName: columnName,  
    width: 150,  
    flex: 1,  
    renderCell: (params) => {  
      const isEditing = editingRowId === params.row.id;  
      if (isEditing) {  
        return (  
          <TextField  
            defaultValue={params.value}  
            onChange={(e) => handleEditChange(e, params.field)}  
            fullWidth  
            multiline  
            rows={4}  
            variant="outlined"  
          />  
        );  
      }  
      return (  
        <div className="cellTextOverflow">  
          {params.value}  
        </div>  
      ); 
    },  
  })); 

  columns.push({  
    field: 'certainty_score',  
    headerName: 'Certainty',  
    width: 100,  
    flex: 1,  
    renderCell: (params) => {  
      if (params.value === '') {  
        return '';  
      } 
      const formattedCertaintyScore = params.value.toFixed(3);  
      return renderConfidence ? renderConfidence(formattedCertaintyScore, confidenceInput) : formattedCertaintyScore;  
    },  
  });
  columns.push({  
    field: 'actions',  
    headerName: 'Actions',  
    width: 80,   
    sortable: false,  
    filterable: false,  
    renderCell: (params) => {  
      const isEditing = editingRowId === params.row.id;  

      if (isEditing) {  
        return (  
       <IconButton data-testid={`save-button-${params.row.id}`} onPress={() => handleSave()}>  
        <SaveIcon />  
      </IconButton> 
        );  
      }  
     
      return (  
        <IconButton data-testid={`edit-button-${params.row.id}`} size="small" onPress={() => handleEditClick(params.row)}>  
        <EditIcon fontSize="inherit" />  
        </IconButton>   
      );  
    }  
  });
   
  return (  
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>  
      <div style={{  flexGrow: 1,width: '95%' }}>
      <div style={{ marginBottom: '10px' }}>  
      </div>
        <DataGrid  
          key={gridKey}
          rows={tableRows}
          columns={columns}  
          getRowHeight={() => 'auto'}  
          autoHeight={true}  
          pagination={false}  
          hideFooterRowCount={true}  
          hideFooter={true}  
          hideToolbarRowCount={true}  
          disableSelectionOnPress 
          onCellClick={handleCellClick}  
        />
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>   
        <Button variant="ghost" onPress = {handleAddRow} size="s">Add Row</Button>
      </div>  
    </div>  
  </div> 
  );  
};  
  
export default CollapsibleTable;
