import axios from 'axios';

const backendApi = process.env.REACT_APP_BACKEND_URL;
const summaryApi = process.env.REACT_APP_Summarization_API;

export async function loadPages(pdfDocument,canvasContainerRef,setCanvasData){
    let newData = [];
    const numPages = pdfDocument.numPages;
      for (let i = 1; i <= numPages; i++) {
        const page = await pdfDocument.getPage(i);
        await drawCanvasOnPage(page, i,canvasContainerRef,setCanvasData,newData);
      }
  }
  
  export const drawCanvasOnPage = async (page,pageNumber,canvasContainerRef,setCanvasData,newData) => {
    const canvas = document.createElement("canvas");
    const canvasContainer = canvasContainerRef.current;
    canvasContainer.appendChild(canvas);
    const ctx = canvas.getContext("2d");
    ctx.globalAlpha=1;
    const viewport = page.getViewport({ scale: 1 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
      canvasContext: ctx,
      viewport: viewport,
      };
      await page.render(renderContext).promise;
      const canvas2=document.createElement("canvas");
      const ctx2=canvas2.getContext("2d");
      canvas2.height=canvas.height;
      canvas2.width=canvas.width;
      ctx2.clearRect(0,0,canvas2.width,canvas2.height);
      canvas.style.position='absolute';
      canvas2.style.position='absolute';
      canvas.style.top=`${(pageNumber -1)*canvas.height}px`;
      canvas2.style.top=`${(pageNumber -1)*canvas.height}px`;
      canvas.style.left='0px';
      canvas2.style.left='0px';
      canvasContainer.appendChild(canvas2);
      setCanvasData((prevData) => {  newData = [...prevData];
          newData[pageNumber - 1] = { ctx2, canvasWidth: canvas2.width, canvasHeight: canvas2.height };
          return newData;
         });
  }
  
  export function tabledrawRect(tableselectedItem,canvasData,prevCoordinates,setPrevCoordinates){
    const xvalues = tableselectedItem.flatMap(item => item.cordinates ?item.cordinates.flatMap(cord=>cord.boundingPolygon.map(point=>point.x)):[]);
    const yvalues = tableselectedItem.flatMap(item => item.cordinates ?item.cordinates.flatMap(cord=>cord.boundingPolygon.map(point=>point.y)):[]); 
    let pageNum = tableselectedItem.find(item=> item.cordinates !==null).cordinates[0].pageNumber;
    const newRectangle={
          pageNumber:pageNum,
          x : Math.min(...xvalues)/tableselectedItem[0].width,
          y : Math.min(...yvalues)/tableselectedItem[0].height,
          width : ((Math.max(...xvalues) - Math.min(...xvalues))/tableselectedItem[0].width),
          height : ((Math.max(...yvalues) - Math.min(...yvalues))/tableselectedItem[0].height),
          };
    const canvasDataEntry = canvasData[pageNum-1];
    stroke(canvasDataEntry,newRectangle,prevCoordinates,setPrevCoordinates);
  }
  
  export function drawRect(selectedItem,canvasData,prevCoordinates,setPrevCoordinates){
    if(selectedItem.last_cordinates)
      {
        let boundPoly = selectedItem.cordinates[0].boundingPolygon;
        let pageNum = selectedItem.cordinates[0].pageNumber;
        let boundPoly1= selectedItem.last_cordinates[0].boundingPolygon; 
        const newRectangle={
          pageNumber:pageNum,
          x : boundPoly[0].x/selectedItem.width,
          y : boundPoly[0].y/selectedItem.height,
          width : ((boundPoly1[1].x - boundPoly[0].x)/selectedItem.width),
          height : ((boundPoly1[2].y - boundPoly[1].y)/selectedItem.height),
          };
          const canvasDataEntry = canvasData[pageNum-1];
          stroke(canvasDataEntry,newRectangle,prevCoordinates,setPrevCoordinates);
      }
      else
      {
      let boundPoly = selectedItem.cordinates[0].boundingPolygon;
      let pageNum = selectedItem.cordinates[0].pageNumber;
      
      const newRectangle={
        pageNumber:pageNum,
        x : boundPoly[0].x/selectedItem.width,
        y : boundPoly[0].y/selectedItem.height,
        width : (boundPoly[1].x - boundPoly[0].x)/selectedItem.width,
        height : (boundPoly[2].y - boundPoly[1].y)/selectedItem.height,
        };
        const canvasDataEntry = canvasData[pageNum-1];
        stroke(canvasDataEntry,newRectangle,prevCoordinates,setPrevCoordinates);
    }
  }

  export function clearCanvas(ctx2){
    if(ctx2){
      ctx2.reset();
    }
  }

  export function stroke(canvasDataEntry,newRectangle,prevCoordinates,setPrevCoordinates){
    if(canvasDataEntry){
      const {ctx2,canvasWidth,canvasHeight} = canvasDataEntry;
      clearCanvas(prevCoordinates);
        const canvasX = newRectangle.x * canvasWidth;
        const canvasY = newRectangle.y * canvasHeight;
        const canvasRectWidth = (newRectangle.width * canvasWidth)+4;
        const canvasRectHeight = (newRectangle.height * canvasWidth)+5.5;
        ctx2.strokeStyle = "#1B9AFF";
        ctx2.lineWidth = 1;
        ctx2.beginPath();
        ctx2.strokeRect(canvasX,canvasY,canvasRectWidth, canvasRectHeight);
        setPrevCoordinates(ctx2);
    }
  }
  
  export async function checkFile(file,setIsLoading,onReceiveResponse,setPdfDocument,setOpen,setintegrityResponseText,handleIntegrityFailed){
    if (file) {
      setIsLoading(true);
      onReceiveResponse(null);
      const reader = new FileReader(); 
      reader.onloadend = async () => {
      const pdf = await pdfjsLib.getDocument(new Uint8Array(reader.result)).promise; 
      setPdf(file,setIsLoading,onReceiveResponse,setPdfDocument,pdf,setOpen,setintegrityResponseText,handleIntegrityFailed);
        };
        reader.readAsArrayBuffer(file);
    };
  }

  export async function setPdf(file,setIsLoading,onReceiveResponse,setPdfDocument,pdf,setOpen,setintegrityResponseText,handleIntegrityFailed){
      const formdata = new FormData();
      formdata.append('file',file);
      try{
        const response = await axios.post(backendApi+'api/test/analyze-custom-doc-model-data', formdata ,{
          headers : {
            'Content-Type' : 'application/octet-stream',
          },
          timeout: 1200000,
        });
        const data = response.data;
        const jsonData = JSON.parse(data.jsonOutput);
        const modelName = data.customModelName;
        const checked = await integrityDialog(jsonData,modelName,setOpen,setintegrityResponseText,handleIntegrityFailed);
        if(checked){
          onReceiveResponse(data);
        }
      }
      catch(error){
        console.error('Error sending data ',error);
      }
      setPdfDocument(pdf);
      setIsLoading(false);  
    }

    export function checkLimit(response, files, setIsLoading, onReceiveResponse, setPdfDocument, setintegrityOpen, setintegrityResponseText,handleIntegrityFailed){

      if (isLimitExceeded(response)) {  
        handleLimitExceeded();  
      } else {  
        checkFile(files, setIsLoading, onReceiveResponse, setPdfDocument, setintegrityOpen, setintegrityResponseText,handleIntegrityFailed);
      }
    }

    export function blurUI() {
      const elementsToBlur = document.querySelectorAll('body > :not(.dialogue-box)');
      elementsToBlur.forEach(element => {
        element.classList.add('blur');
      });
    }
    
     export function showDialogueBox(message,redirect = (url) => window.location.assign(url)) {
      const dialogueBox = document.createElement('div');
      dialogueBox.classList.add('dialogue-box');
    
      const messageElement = document.createElement('p');
      messageElement.textContent = message;
    
      const okButton = document.createElement('button');
      okButton.textContent = 'OK';
      okButton.addEventListener('click', function() {   
      redirect('/');
    });
    
       document.body.appendChild(dialogueBox);
       dialogueBox.appendChild(messageElement);
       dialogueBox.appendChild(okButton);
     }
          
    export async function checkUploadLimit() {  
      const dateTime = new Date().toISOString().split('T')[0]; 
      const response = await fetch(backendApi+'api/login/limit', {  
        method: 'POST',  
        headers: {  
          'Content-Type': 'application/x-www-form-urlencoded'  
        },  
        body: new URLSearchParams({  
          Date: dateTime  
        })  
      });  
      return response;  
    }  
      
    export function isLimitExceeded(response) {  
      return response.status !== 200 && response.status !== 202;  
    }  
      
    export function handleLimitExceeded() {  
      blurUI();  
      showDialogueBox('" Oops! Your limit has been exceeded "');  
    }

    export const integrityDialog = async (responseData,modelName, setintegrityOpen, setintegrityResponseText,handleIntegrityFailed) => {  
      try {  
        const status = await performIntegrityStatus();  
        if (status) {  
          console.log(status);
          const integrityResult = await handleIntegrityCheck(responseData,modelName);  
          console.log(integrityResult);
          const check = await checkIntegrity(integrityResult, setintegrityResponseText, setintegrityOpen,handleIntegrityFailed);  
          return check;  
        } else {  
          return true;  
        }  
      } catch (error) {  
        console.error(error);  
      }  
    };  
      
    export async function handleIntegrityCheck(gridRows,modelName) {  
      const keyColumn = gridRows.map((row) => row.key);  
      const valueColumn = gridRows.map((row) => row.value);  
      let pages = gridRows[0].noOfPages;  
      const dict = {};  
      keyColumn.forEach((key, index) => {  
        dict[key] = valueColumn[index];  
      });  
      dict.number_of_pages = pages;  
        const data = {
          formData:dict,
          customModelName:modelName
        };
      const response = await axios.post(summaryApi + 'api/process/form_integrity', data);  
      return response.data;  
    }  
      
    export async function performIntegrityStatus() {  
      const response = await axios.get(backendApi + 'api/admin/formIntegrity-status');  
      const status = response.data;  
      return status;  
    }  
      
    export async function checkIntegrity(integrityResult, setintegrityResponseText, setintegrityOpen,handleIntegrityFailed) {  
      const lines = integrityResult.split("\n");  
      const dictionary = createDictionary(lines);  
      const failureChecks = getFailureChecks(dictionary);  
      const parsedData = parseLines(lines);  
      
      setintegrityOpen(true);  
      setintegrityResponseText(parsedData);  
      handleIntegrityFailed(failureChecks.length !== 0);    
      return failureChecks.length === 0;  
    }  
    
    export function createDictionary(lines) {  
      let dictionary = {};  
        
      for (let line of lines) {  
        const [field, value] = line.split(":").map(item => item.trim());  
        dictionary[field] = value;  
      }  
        
      return dictionary;  
    }  
      
    export function getFailureChecks(dictionary) {  
      let failureChecks = [];  
        
      for (let key in dictionary) {  
        if (dictionary[key] === 'Failure') {  
          failureChecks.push(key);  
        }  
      }  
        
      return failureChecks;  
    }  
      
    export function parseLines(lines) {  
      let parsedData = [];  
      let currentItem = null;  
      
      lines.forEach((line, index) => {  
        if (line.trim() === '') {  
          return; 
        }  
      
        if (line.includes('Failure')) {  
          if (currentItem) {  
            parsedData.push(currentItem);  
          }  
          currentItem = parseLine(line, index);  
        } else if (line.includes('Comment')) {  
          currentItem.comments = line.split(':').slice(1).join(':').trim();  
        } else {  
          if (currentItem) {  
            parsedData.push(currentItem);  
            currentItem = null;  
          }  
          parsedData.push(parseLine(line, index));  
        }  
      });  
      
      if (currentItem) {  
        parsedData.push(currentItem);  
      }  
      
      return parsedData;  
    }      
      
    export function parseLine(line, index) {  
      const [checkName, status] = line.split(':');  
      return {  
        id: index,  
        checkName: checkName.trim() === 'NOT NULL Check' ? 'Mandatory Field Check' : checkName.trim(),  
        status: status?.trim() || ''  
      };  
    }   