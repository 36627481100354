import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { TextInput } from '@optum-osgp-temp/osgp-ui-component-lib';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import { Navigate } from 'react-router-dom';  


const backendApi = process.env.REACT_APP_BACKEND_URL;
const summaryApi = process.env.REACT_APP_Summarization_API;

const UseStyles = makeStyles((theme) => ({
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none', 
    },
  },
}));

export function renderConfidence(params,confidenceInput) {
  const confidenceValue = parseFloat(params.value);
  const confidence =  confidenceValue.toFixed(3);
  const textColor=confidence>confidenceInput ? 'black' : 'red';
    return(
      <div style={{color:textColor,right:'2px'}}>{confidence}</div> 
    );
}
  
  export const fetchCertaintyFromApi = async () => {
    try {
      const response = await axios.get(backendApi + 'api/blobs/certainty');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export function handleConfidenceInputChange(event,setConfidenceInput,setInfoOpen,threshold){
    const inputValue = parseFloat(event.target.value);
    if(inputValue < threshold && inputValue > 0){
        setInfoOpen(true);
        setConfidenceInput(threshold);
    } 
    else{
      setConfidenceInput(inputValue);
    }
  }

export function getVal(name){
      let x;
      if(name==='High'){
        x = 300;
       }
       if(name==='Medium'){
         x=200;
       }
       if(name==='Low'){
         x=100;
       }
       if(name==='N/A'){
         x=0;
       }
       return(x);
    }

export function handleStatusChange(gridRows,params, e,setGridRows,setTotalWeight,setTotalWeightedConfidence){
  const newRows = gridRows.map(row =>
      row.id === params.id ? { ...row, weights: e.target.value } : row
     );
  const status = e.target.value;
  let deno=0;
  let numo=0
  for(let i=0;i<gridRows.length;i++){
    if(i===(params.id-1)){
      numo+=gridRows[i].confidenceValue*getVal(status);
      deno+=getVal(status);
    }
    else{
      numo+=gridRows[i].confidenceValue*getVal(gridRows[i].weights);
      deno+=getVal(gridRows[i].weights)
    }
   };
   setGridRows(newRows);
   setTotalWeight(deno);
   setTotalWeightedConfidence(numo);
}

export const renderWeights = (params,handleStatusChangeWrap) => {
  const classes = UseStyles(); 
  return(
    <div className="single-select-cell">
        <Select
          value={params.value}
          onChange={(e) => handleStatusChangeWrap(params, e)}
          variant="outlined"
          className={classes.select}>
          <MenuItem value="High">High</MenuItem>
          <MenuItem value="Medium">Medium</MenuItem>
          <MenuItem value="Low">Low</MenuItem>
          <MenuItem value="N/A">N/A</MenuItem>
        </Select>
      </div>
      );
}

export function handleEditClick(params,setEditingIndex,setEditedValue){
  const index = params.row.id;
  setEditingIndex(index);
  // console.log(index);
  setEditedValue(params.value);
}

export const ProtectedRoute = ({ isVerified, children }) => {  
  if (!isVerified) {   
    return <Navigate to="/" replace />;  
  }  
  return children;  
};
export default ProtectedRoute

export async function loginInformation(userName, password,setIsVerified){
  try {
    const response = await fetch(backendApi+"api/login/details", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        userName: userName,
        password: password,
      })
    });
    console.log(response);
    if (response.status === 200 || response.status === 202) {
      setIsVerified(true);
    } else {
      (alert('The login credentials which you have entered are invalid'));
    }
  } catch (error) {
    console.error(error);
    throw error;
  } 
}

export function handleReturn(gridRows,fileName,userName,data) {  
  const jsonData = gridRows.reduce((acc, row) => {   
    if (row.fieldType === 'array' && row.arrayData && Array.isArray(row.arrayData.value)) {    
      const tableData = row.arrayData.value.map((tableRowObject) => {   
        const rowData = {};  
        tableRowObject.value.forEach((cell) => {  
          if (cell.key !== null && cell.value !== undefined) {  
            rowData[cell.key] = cell.value;  
          }  
        });  
        return rowData;  
      });  

      if (tableData.length > 0) {  
        acc[row.keyContent] = tableData;  
      }  
    } else {  
      const key = row.keyContent;  
      const value = row.valueContent;  
      if (key !== null && value !== undefined) {  
        acc[key] = value;  
      }  
    }  
    return acc;  
  }, {});  
  const object = {
    editedJson : jsonData,
    userName: userName,
    fileName : fileName,
    originalData : data
  };
  axios.post(backendApi+'api/upload/json', object)  
    .then((response) => {  
      if (response.status === 200 || response.status === 202) {  
        alert('Successful Upload!!!');  
      }  
    })  
    .catch((error) => {  
      console.error(error);  
    });  
}   

export function handleEditChange(event,setEditedValue){
  const newValue = event.target.value;
  setEditedValue(newValue);
}

export function handleStopEditing(params,setEditingIndex,gridRows,setGridRows,editedValue){
  const index = params.row.id;
  const newRows = gridRows.map((row) => row.id === index ? {...row,valueContent:editedValue}:row);
  setGridRows(newRows);
  // console.log(gridRows[index-1].keyContent);
  setEditingIndex(null);
}

export function handleKeyDown(e,editedValue,setEditedValue){
  if(e.key === ' '){
    e.preventDefault();
    e.stopPropagation();
    setEditedValue(editedValue+" ");
  }
}

export function handleRadioChange(index,value,setEditedValue,gridRows,params,setGridRows,checked,setChecked){
    const newstate = [...checked];
    newstate[index] = !newstate[index];
    
    if(value.includes(':selected:') || value.includes(':unselected:')){
    const valueArray = value.split(";");
    let updatedvalue;
    if(valueArray.length>1){
    const [label,] = valueArray[index].trim().split("-");
    valueArray[index] =`${label}-${newstate[index] ? ':selected:' : ':unselected:'}`;
    updatedvalue =valueArray.join(";");
    setEditedValue(updatedvalue);
    }
    else{
      valueArray[0] =newstate[index] ? ':selected:' : ':unselected:';
      updatedvalue =valueArray[0];
      setEditedValue(updatedvalue);
    }
    const updatedGridRows = gridRows.map((row)=>{
      if (row.id === params.row.id)
      {
        return { ...row,valueContent: updatedvalue};
      }
      return row;
    });
    setGridRows(updatedGridRows);
    setChecked(newstate);   
  };
}

export function renderValueCell(params,setGridRows,gridRows,setEditingIndex,setEditedValue,editedValue,editingIndex,checked,setChecked){
  const value = params.value;
  checked =false;

  if (!/^\d+(\.\d+)?$/.test(value) && (value.includes(':selected:') || value.includes(':unselected:')) )
   {    
    const valueArray = value.split(";");
    const initialCheckedStates = valueArray.map((item)=> item.includes(":selected:"));
    checked=initialCheckedStates;
    if (valueArray.length > 1){
      const radiobuttons = valueArray.map((item,index) =>{
        const[label,] =item.trim().split("-").map(part => part.trim());
  
        return(
        <div key={index} style={{ display:'block',alignItems:'center'}}>
            <input type="radio" checked={checked[index]} />
            <EditIcon style={{fontSize:'small',position:'relative',left:180,top:2}} onPress= {(e) => handleRadioChange(index,value,setEditedValue,gridRows,params,setGridRows,checked,setChecked)} data-testid={`radioButton${index}`}/>
            <label style={{display:'block',alignItems:'center',whiteSpace:'pre-wrap'}}>{label}</label>        
        </div>
        );
        
      });
      return <div style={{display: "block"}}>{radiobuttons}</div>
    }
    else{
      return(
        <div style={{ display:'block',alignItems:'center',whiteSpace:'pre-wrap'}}>
            <input type="radio" checked={checked[0]}/>
            <EditIcon style={{fontSize:'small',position:'relative',left:180,top:2}} onPress= {(e) => handleRadioChange(0,value,setEditedValue,gridRows,params,setGridRows,checked,setChecked)} data-testid="radioButton0"/>
        </div>
        );      
    }
  }
  
else{
    return(     
      parseInt(editingIndex,10) === params.row.id ? (
      <div>
        
        <EditIcon style={{fontSize:'small',position:'relative',left:220,top:2}} onPress= {(e) => handleStopEditing(params,setEditingIndex,gridRows,setGridRows,editedValue)} data-testid = "StopeditIcon"/>
        <TextInput type={"string"} data-testid={'inputText'} value={editedValue} style={{ width: '200px',fontSize:'13px',whiteSpace:'pre-wrap',alignItems:'center'}} onChange={(e) => handleEditChange(e,setEditedValue)} onKeyDown={(e) => handleKeyDown(e,editedValue,setEditedValue)}/>
      </div> 
    ):
    (
      <>
      <div style={{alignItems:'center',whiteSpace:'pre-wrap'}}>
        <EditIcon onPress= {(e) => handleEditClick(params,setEditingIndex,setEditedValue)} style={{fontSize:'small',position:'relative',left:210,top:2}}/>
        {value}
      </div>
      </>
    )
    );
  }
}

let totalWeights = 0;
let totalConfidence=0;
export function calculateTotalWeightedAverage(data,setTotalWeightedConfidence,setTotalWeight){
  totalConfidence=0
  totalWeights = 0;
  if (data && data.length > 1) {  
    const totalWeightedConfidence = data.reduce((total, item) => {  
    const confidence = item.confidenceValue;
    const weight = getVal(item.weights);
    totalConfidence = totalConfidence + confidence*(weight/100);
    totalWeights=totalWeights+(weight/100);
    return total+confidence*(weight/100);
  },0);
  setTotalWeightedConfidence(totalWeightedConfidence);
  setTotalWeight(totalWeights);
  }
  else{
    setTotalWeightedConfidence(0);
  }
}

export function newRows(data,weightData) {  
  if (!data) {  
    return [];  
  }  
  return data.map((item, index) => {  
    const weight = weightData && item.key && weightData[item.key] ? weightData[item.key] : 'Medium';  
    const row = {  
      id: index,  
      keyContent: item.key || '',  
      valueContent: item.value || '',  
      confidenceValue: item.certainty_score || '',  
      weights: weight,  
      fieldType: item.field_type || '',   
    };  
    // Check if the item is an array and set the arrayData property  
    if (item.field_type === 'array') { 
      //console.log("RowArray "+row.keyContent + item.columns + item.value ); 
      row.arrayData = {  
        columns: item.columns || [], // Ensure columns is an array  
        value: item.value || [] // Ensure value is an array  
      };  
      //console.log("Arraydata "+row.arrayData);
      row.isCollapsible = true;  
    }  
  
    return row;  
  });  
}  

export const summaryDialog = async (setResponseText,gridRows,setOpen,modelName) => {  
  try {  
    const result = await handleSummarization(gridRows,modelName);  
    setResponseText(result);  
    setOpen(true); 
  } catch (error) {  
    console.error(error);  
  }  
}

export function handleSummarization(gridRows,modelName){  
  const keyColumn = gridRows.map((row) => row.keyContent);  
  const valueColumn = gridRows.map((row) => row.valueContent);  
  const dict = {};  
  keyColumn.forEach((key,index) => {  
    dict[key] = valueColumn[index];  
  });  
  const jsonData = {
    formData:dict,
    customModelName:modelName
  };
  return axios.post(summaryApi+'api/process/summarization', jsonData)    
  .then(response => {        
    return response.data;   
  })    
  .catch(error => {    
    console.error(error);  
    throw error;  
  });   
} 

export const fetchDataFromApi = async () => {
  try {
    const response = await axios.get(backendApi + 'api/blobs/latest');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const fetchWeightsData = async (setWeights,setConfidenceInput) => {
  try{
    const weightData = await fetchDataFromApi();
    const weightsArr = Object.values(weightData);
    let certanity = weightsArr[0].value;
    setWeights(weightData);
    setConfidenceInput(certanity);
  }
  catch(error){
    console.error('Error:',error);
  }
}

export async function filterIntegrityFieldData(jsonData){
  const response = await axios.get(backendApi+'api/admin/formIntegrity-fields');
  const keysToRemove = response.data;
  if(keysToRemove.length === 0){
    return jsonData;
  }
  return jsonData.filter(row => !keysToRemove.includes(row.keyContent)); 
}

export const highlightDifferingValue = (currentValue, row) => {  
  if (currentValue === 'null' || currentValue === 'no') {  
    return 'redText';  
  }  
  const values = Object.values(row).filter(val => val !== row.id); 
  const nonNullValues = values.filter(val => val !== 'null' && val.trim() === val);  
  const valueOccurrences = nonNullValues.reduce((acc, val) => {  
    acc[val] = (acc[val] || 0) + 1;  
    return acc;  
  }
  , {});  
  const mostCommonValue = Object.keys(valueOccurrences).reduce((a, b) => valueOccurrences[a] > valueOccurrences[b] ? a : b, '');  
  if (currentValue !== mostCommonValue) {  
    return 'redText';  
  }  
  return 'centerCellContent';  
};  

// export const extractFields = (data) => {  
//   console.log(data);
//   const fieldsSet = new Set();  
//   data.forEach(item => {  
//     Object.keys(item).forEach(key => {  
//       if (key !== "filename" && key!== "Title of Form" ) {  
//         fieldsSet.add(key);  
//       }  
//     });  
//   });  
//   return Array.from(fieldsSet);  
// };
export const extractFields = (data) => {
  const result = [];
  let id = 0; // Initialize the id variable

  if (data.itemIdList && Array.isArray(data.itemIdList)) {
    data.itemIdList.forEach(item => {
      if (item.attachmentList && Array.isArray(item.attachmentList)) {
        item.attachmentList.forEach(attachment => {
          const extractedValue = attachment.extractedValue || {};
          result.push({
            id: ++id, // Increment the id and assign it to the object
            filename: attachment.filename || "null",
            formTitle: extractedValue.formTitle || "null",
            name: extractedValue.name || "null",
            dateOfBirth: extractedValue.dateOfBirth || "null",
            mhid: extractedValue.mhid || "null",
            ssn: extractedValue.ssn || "null",
            isSignaturePresent: extractedValue.isSignaturePresent || "null",
          });
        });
      }
    });
  }

  return result;
};

// export const transformData = (data, fields) => {  
//   return fields.map(field => {  
//     return {  
//       id: field,  
//       ...data.reduce((acc, curr) => {  
//         acc[curr["filename"]] = curr[field] !== null ? curr[field] : "null";  
//         return acc;  
//       }, {})  
//     };  
//   });  
// };

export const transformData = (data) => {
  return data.map(item => ({
    id: item.id,
    filename: item.filename,
    formTitle: item.formTitle,
    name: item.name,
    dateOfBirth: item.dateOfBirth,
    mhid: item.mhid,
    ssn: item.ssn,
    isSignaturePresent: item.isSignaturePresent
    
  }));
};