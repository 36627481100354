import React from 'react';  
import { Box, Typography } from '@mui/material'; 
import axios from 'axios';  
  
const backendApi = process.env.REACT_APP_PROCESSOR_URL;   
  
export const TabPanel = (props) => {  
    const { children, value, index, ...other } = props;  
  
    return (  
        <div  
            role="tabpanel"  
            hidden={value !== index}  
            id={`tabpanel-${index}`}  
            aria-labelledby={`tab-${index}`}  
            {...other}  
        >  
            {value === index && (  
                <Box>  
                    <Typography>{children}</Typography>  
                </Box>  
            )}  
        </div>  
    );  
};  

  
// Utility Functions  
export const handleFileChange = (event, setFiles) => {  
    setFiles(event.target.files);  
};  
  
export const createFormData = (files) => {  
    const formData = new FormData(); 
    console.log(""+formData);  
    Array.from(files).forEach(file => {  
        formData.append('files', file);  
    });
    console.log("--"+formData);  
    return formData;  
};  

export const uploadFiles = async (formData) => {  
    console.log(backendApi);
    return await axios.post(`${backendApi}api/v4/IngressMLCoreV4_UI_DocInt`, formData, {  
        headers: {  
            'Content-Type': 'multipart/form-data',
            'tenant': 'dip',
            'queue': 'queueName',
            'environment': 'test'  
        }  
    });  
};
    
// Event Handler Functions  
export const onFileChange = (event, setFiles) => {  
    handleFileChange(event, setFiles);  
};  
  
export const handleUpload = async (files, setIsLoading, setUploadedFiles, setFiles, setApiData) => {  
    const formData = createFormData(files);  
    setIsLoading(true);  
    setApiData([]);
  
    try {  
        const response = await uploadFiles(formData); 
        console.log(response); 
        setUploadedFiles(Array.from(files));  
        setFiles([]); // Clear the input after upload  
        setApiData(response.data);  
    } catch (error) {  
        console.error('Error during file upload:', error);  
    }  
  
    setIsLoading(false);  
};  
  
export const handleTabChange = (event, newValue, setSelectedTab) => {  
    setSelectedTab(newValue);  
};

// Zoom Handling Functions  
export const handleZoomIn = (setZoomLevel) => {  
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));  
};  
  
export const handleZoomOut = (setZoomLevel) => {  
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));  
};  
