// import { DataGrid } from '@optum-osgp-temp/osgp-ui-composite-comp-lib';
// import { TextInput,Label,Icon, Tooltip,Button,Notification } from '@optum-osgp-temp/osgp-ui-component-lib';
// import React, { useState, useEffect, useCallback } from 'react';  
// import CollapsibleTable from './CollapsibleTable'; 
// import './DataGrid.css';
// import { handleConfidenceInputChange, renderConfidence,renderValueCell,handleReturn,calculateTotalWeightedAverage,newRows,fetchWeightsData, filterIntegrityFieldData, fetchCertaintyFromApi } from '../Utility/reUsable';

// const DefaultExample = ({userName,fileName,data,sumarizeData,onFieldClick,TableFieldClick}) => { 
//   const [confidenceInput,setConfidenceInput] = useState(0.85);
//   const [totalWeightedConfidence,setTotalWeightedConfidence] = useState(0);
//   const [weights,setWeights] = useState(null);
//   const [totalWeight,setTotalWeight] = useState(0);
//   const [editingIndex,setEditingIndex] = useState(null);
//   const [editedValue,setEditedValue] = useState('');
//   const [gridRows, setGridRows] = useState([]);  
//   const [checked, setChecked] = useState([]);
//   const [infoOpen, setInfoOpen] = useState(false);
//   const dismissInfo = () => setInfoOpen(false);
//   const [threshold,setThreshold] = useState(0);

//   useEffect(async () => {   
//     fetchWeightsData(setWeights,setConfidenceInput); 
//     let tableData = newRows(data,weights);  
//     let filterData = await filterIntegrityFieldData(tableData);
//     setGridRows(filterData);
//     let value = await fetchCertaintyFromApi();
//     setThreshold(value);
//   }, [data]);

//   useEffect(() => {
//     calculateTotalWeightedAverage(gridRows,setTotalWeightedConfidence,setTotalWeight); 
//   },[gridRows,weights]);
  
//   useEffect(() => {
//     sumarizeData(gridRows);
//   },[gridRows]);
  
//   const columns = [
//       {field:'keyContent', headerName:'Form Fields',flex:2.5,whiteSpace:'pre-wrap',alignItems:'center'},
//       {field:'valueContent',headerName:'Extracted Values',flex:3,renderCell:(params) => {  
//         if (params.row.fieldType === 'array') {  
//           return ' '; 
//         }  
//         return renderValueCell(params,setGridRows,gridRows,setEditingIndex,setEditedValue,editedValue,editingIndex,checked, setChecked);  
//       }},  
//       {field:'confidenceValue',headerName:'Certainty',flex:1.5,renderCell:(params) => renderConfidence(params,confidenceInput)},
//       {field:'weights',headerName:'Weights',flex:1.3},
//     ];

//     function handleConfidenceInputChangeWrap(event){
//       handleConfidenceInputChange(event,setConfidenceInput,setInfoOpen,threshold);
//     }
 
//   const getDetailPanelHeight = useCallback((row) => {  
//     return 200;  
//   }, []);

//   const updateGridRow = (rowId, updatedTableData) => {  
//     setGridRows(currentGridRows =>  
//       currentGridRows.map(gridRow =>  
//         gridRow.id === rowId && gridRow.fieldType === 'array'  
//           ? { ...gridRow, arrayData: { ...gridRow.arrayData, value: updatedTableData } }  
//           : gridRow  
//       )  
//     );  
//   };  
  
//   const getDetailPanelContent = React.useCallback(  
//     ({ row }) => {  
//       if (row.fieldType === 'array') {  
//         return(   
//           <CollapsibleTable   
//             arrayData={row.arrayData}  
//             confidenceInput={confidenceInput} 
//             TableFieldClick={TableFieldClick}
//             rowId={row.id} 
//             updateGridRow={updateGridRow} 
//           />  
//         );   
//       }  
//       return null;  
//     },  
//     [confidenceInput],  
//   );  
  
//     const totalRows=gridRows.length; 
//     const averageWeightedConfidence = totalWeight == 0 ? totalWeightedConfidence/totalRows : totalWeightedConfidence/totalWeight;
//     return(
//       <div>
//         <div className="container">
//           <Label style={{marginTop:'5px',marginLeft:'8px'}}>Threshold Certainty<Tooltip content="Certainty score for every field below which human review is mandatory" placement='bottom' maxWidth='350px'>
//             <Icon iconName="InfoFilled"/>
//             </Tooltip> : </Label>
//            <TextInput className="input" style={{ width: '100px',marginLeft:'5px'}} type={'number'} min='0' max='1' step={0.01} value={confidenceInput} onChange={handleConfidenceInputChangeWrap}></TextInput>
//            <Label className='quotient'>Certainty Quotient<Tooltip content="Combined Certainty score which reflects relative importance of each field based on weights set by the user" placement='bottom-end' maxWidth='390px'>
//            <Icon iconName="InfoFilled"/>
//            </Tooltip> : <a style={{ color:'green' }}>{averageWeightedConfidence.toFixed(4)}</a></Label>
//         </div>
//         <Notification
//         onDismiss={dismissInfo}
//         buttonChildren={"close"}
//         variant={"info"}
//         id={"notification-controlled-info"}
//         show={infoOpen}
//         className="mv-m"
//         style={{maxWidth:"50vw",cursor:"pointer",marginTop:"1%"}}>Admin has configured a threshold floor value of {threshold}. Please enter a value above the threshold.</Notification>
//          <Button className='submitButton' variant="ghost" onPress = {() => handleReturn(gridRows,fileName,userName,data)} data-testid='SubmitBtn'>Submit</Button>
//         <div className='datagrid'>
//          <DataGrid 
//           autoHeight={true}
//           getRowHeight={() => 'auto'} 
//           key={gridRows.length}  
//           columns={columns}
//           rows={gridRows}
//           getDetailPanelHeight={getDetailPanelHeight}
//           getDetailPanelContent= {getDetailPanelContent}
//           editMode="row"
//           disableToolbarFilter
//           pageSizeOptions={[60]} 
//           disableRowSelectionOnPress
//           onCellClick={(params)=> onFieldClick(params)}
//         />
//       </div>
//     </div>
//     );
// }
// export default DefaultExample

import { DataGrid,GridToolbarContainer, GridToolbarExport,GridToolbarFilterButton } from '@mui/x-data-grid';
// import { DataGrid } from '@optum-osgp-temp/osgp-ui-composite-comp-lib';  
import { TextInput, Label, Icon, Tooltip, Button, Notification } from '@optum-osgp-temp/osgp-ui-component-lib';  
import React, { useState, useEffect, useCallback, useMemo } from 'react';  
import CollapsibleTable from './CollapsibleTable';  
import './DataGrid.css';  
import {  
  handleConfidenceInputChange,  
  renderConfidence,  
  renderValueCell,  
  handleReturn,  
  calculateTotalWeightedAverage,  
  newRows,  
  fetchWeightsData,  
  filterIntegrityFieldData,  
  fetchCertaintyFromApi  
} from '../Utility/reUsable';  
  
const DefaultExample = ({ userName, fileName, data, sumarizeData, onFieldClick, TableFieldClick }) => {  
  const [confidenceInput, setConfidenceInput] = useState(0.85);  
  const [totalWeightedConfidence, setTotalWeightedConfidence] = useState(0);  
  const [weights, setWeights] = useState(null);  
  const [totalWeight, setTotalWeight] = useState(0);  
  const [editingIndex, setEditingIndex] = useState(null);  
  const [editedValue, setEditedValue] = useState('');  
  const [gridRows, setGridRows] = useState([]);  
  const [checked, setChecked] = useState([]);  
  const [infoOpen, setInfoOpen] = useState(false);  
  const [threshold, setThreshold] = useState(0);  
  
  const dismissInfo = () => setInfoOpen(false);  
  
  useEffect(() => {  
    const fetchData = async () => {  
      await fetchWeightsData(setWeights, setConfidenceInput);  
      const tableData = newRows(data, weights);  
      const filterData = await filterIntegrityFieldData(tableData);  
      setGridRows(filterData);  
      const value = await fetchCertaintyFromApi();  
      setThreshold(value);  
    };  
    fetchData();  
  }, [data]);  
  
  useEffect(() => {  
    calculateTotalWeightedAverage(gridRows, setTotalWeightedConfidence, setTotalWeight);  
  }, [gridRows, weights]);  
  
  useEffect(() => {  
    sumarizeData(gridRows);  
  }, [gridRows]);  
  
  const columns = useMemo(() => [  
    { field: 'keyContent', headerName: 'Form Fields', flex: 2.5, whiteSpace: 'pre-wrap', alignItems: 'center' },  
    {  
      field: 'valueContent',  
      headerName: 'Extracted Values',  
      flex: 3.5,  
      renderCell: (params) => {  
        if (params.row.fieldType === 'array') {  
          return ' ';  
        }  
        return renderValueCell(params, setGridRows, gridRows, setEditingIndex, setEditedValue, editedValue, editingIndex, checked, setChecked);  
      }  
    },  
    { field: 'confidenceValue', headerName: 'Certainty', flex: 1.5, renderCell: (params) => renderConfidence(params, confidenceInput) },  
    { field: 'weights', headerName: 'Weights', flex: 1.3 },  
  ], [gridRows, editedValue, editingIndex, checked, confidenceInput]);  
  
  const handleConfidenceInputChangeWrap = useCallback((event) => {  
    handleConfidenceInputChange(event, setConfidenceInput, setInfoOpen, threshold);  
  }, [threshold]);  
  
  const getDetailPanelHeight = useCallback(() => 200, []);  
  
  const updateGridRow = useCallback((rowId, updatedTableData) => {  
    setGridRows(currentGridRows =>  
      currentGridRows.map(gridRow =>  
        gridRow.id === rowId && gridRow.fieldType === 'array'  
          ? { ...gridRow, arrayData: { ...gridRow.arrayData, value: updatedTableData } }  
          : gridRow  
      )  
    );  
  }, []);  
  
  const getDetailPanelContent = useCallback(({ row }) => {  
    if (row.fieldType === 'array') {  
      return (  
        <CollapsibleTable  
          arrayData={row.arrayData}  
          confidenceInput={confidenceInput}  
          TableFieldClick={TableFieldClick}  
          rowId={row.id}  
          updateGridRow={updateGridRow}  
        />  
      );  
    }  
    return null;  
  }, [confidenceInput, TableFieldClick, updateGridRow]);  
  
  const totalRows = gridRows.length;  
  const averageWeightedConfidence = totalWeight === 0 ? totalWeightedConfidence / totalRows : totalWeightedConfidence / totalWeight;  
  
  const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            {/* <GridToolbarFilterButton sx={{ color: 'darkblue' }} /> */}
        <GridToolbarExport sx={{ color: 'darkblue' }} />
        </GridToolbarContainer>
    );
}; 
  return (  
    <div>  
      <div className="container">  
        <Label style={{ marginTop: '5px', marginLeft: '8px' }}>  
          Threshold Certainty  
          <Tooltip content="Certainty score for every field below which human review is mandatory" placement='bottom' maxWidth='350px'>  
            <Icon iconName="InfoFilled" />  
          </Tooltip> :  
        </Label>  
        <TextInput  
          className="input"  
          style={{ width: '100px', marginLeft: '5px' }}  
          type={'number'}  
          min='0'  
          max='1'  
          step={0.01}  
          value={confidenceInput}  
          onChange={handleConfidenceInputChangeWrap}  
        />  
        <Label className='quotient'>  
          Certainty Quotient  
          <Tooltip content="Combined Certainty score which reflects relative importance of each field based on weights set by the user" placement='bottom-end' maxWidth='390px'>  
            <Icon iconName="InfoFilled" />  
          </Tooltip> :  
          <a style={{ color: 'green' }}>{averageWeightedConfidence.toFixed(4)}</a>  
        </Label>  
      </div>  
      <Notification  
        onDismiss={dismissInfo}  
        buttonChildren={"close"}  
        variant={"info"}  
        id={"notification-controlled-info"}  
        show={infoOpen}  
        className="mv-m"  
        style={{ maxWidth: "50vw", cursor: "pointer", marginTop: "1%" }}  
      >  
        Admin has configured a threshold floor value of {threshold}. Please enter a value above the threshold.  
      </Notification>  
      <Button className='submitButton' variant="ghost" onPress={() => handleReturn(gridRows, fileName, userName, data)} data-testid='SubmitBtn'>Submit</Button>  
      <div className='datagrid'>  
        <DataGrid  
          autoHeight={true}  
          getRowHeight={() => 'auto'}  
          key={gridRows.length}  
          columns={columns}  
          rows={gridRows}  
          getDetailPanelHeight={getDetailPanelHeight}  
          getDetailPanelContent={getDetailPanelContent}  
          editMode="row"  
          disableToolbarFilter  
          pageSizeOptions={[60]}  
          disableRowSelectionOnPress  
          onCellClick={(params) => onFieldClick(params)}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
                borderBottom: '2px solid orange',
            },
            '& .MuiTablePagination-root': {
              display: 'none',
            },
            '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                lineHeight: '1.15',
            },
            '& .MuiDataGrid-footerContainer': {
                display: 'none',
            },  
        }}     
        />  
      </div>  
    </div>  
  );  
};  
  
export default DefaultExample;  