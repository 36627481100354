import React from 'react';  
// import { DataGrid } from '@optum-osgp-temp/osgp-ui-composite-comp-lib';  
import { DataGrid,GridToolbarContainer, GridToolbarExport,GridToolbarFilterButton } from '@mui/x-data-grid';
import './DataGrid.css';  
import { highlightDifferingValue, extractFields, transformData } from '../Utility/reUsable';  
  
const DataGridDemo = ({ data }) => {  
    if (data.length === 0) {  
        return <div></div>;  
    }  
  
    const fields = extractFields(data);  
    const transformedData = transformData(fields);  
    console.log(fields);
  
    const renderHeader = (params) => (  
        <div className="headerCellContent">  
            <strong>{params.colDef.headerName}</strong>
        </div>  
    ); 
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                {/* <GridToolbarFilterButton sx={{ color: 'darkblue' }} /> */}
            <GridToolbarExport sx={{ color: 'darkblue' }} />
            </GridToolbarContainer>
        );
    }; 
  
    const columns = [  
        { field: 'filename', headerName: 'File Name', flex: 1.5, renderHeader: renderHeader },
        { field: 'formTitle', headerName: 'Form Title',flex: 1.5, renderHeader: renderHeader },
        { field: 'name', headerName: 'Name', flex: 1.5, renderHeader: renderHeader },
        { field: 'dateOfBirth', headerName: 'Date of Birth', flex: 1.25, renderHeader: renderHeader },
        { field: 'mhid', headerName: 'MHID', flex: 1, renderHeader: renderHeader },
        { field: 'ssn', headerName: 'SSN', flex: 1, renderHeader: renderHeader },
        { field: 'isSignaturePresent', headerName: 'Is Signature Present', flex: 1.5, renderHeader: renderHeader }
    ];  
  
    return (  
        <div className="data-grid-container" style={{ height: 550, width: '100%',marginTop: '50px' }}>
            <DataGrid  
                rows={transformedData}
                columns={columns}  
                disableAdvanceFilters  
                disableToolbarFilter  
                pagination={false}
                getRowHeight={() => 'auto'}
                slots={{ toolbar: CustomToolbar }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        borderBottom: '2px solid orange',
                    },
                    '& .MuiTablePagination-root': {
                      display: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: '1.15',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        display: 'none',
                    },
                }}
            />  
        </div>  
    );  
};

export default DataGridDemo;
// import React from 'react';  
// import { DataGrid } from '@optum-osgp-temp/osgp-ui-composite-comp-lib';  
// import './DataGrid.css';  
// import { highlightDifferingValue, extractFields, transformData } from '../Utility/reUsable';  
  
// const DataGridDemo = ({ data }) => {  
//     if (data.length === 0) {  
//         return <div></div>;  
//     }  
  
//     const fields = extractFields(data);  
//     const transformedData = transformData(fields);  
//     console.log(fields);
  
//     const renderHeader = (params) => (  
//         <div className="headerCellContent">  
//             <strong>{params.colDef.headerName}</strong>
//         </div>  
//     );  
  
//     const columns = [  
//         {  
//             field: 'id',  
//             headerName: 'Field',  
//             width: 150,  
//             renderCell: (params) => (<strong className='centerCellContent'>{params.value}</strong>),  
//             renderHeader: renderHeader  
//         } ,
//         ...data.map(item => ({  
//             field: item["filename"],  
//             headerName: item["filename"],  
//             width: 250, // Increase the width to accommodate longer text  
//             editable: false,  
//             renderCell: (params) => {  
//                 const row = transformedData.find(row => row.id === params.id);  
//                 const cellClass = row && row.id === "Title of Form" ? 'centerCellContent' : highlightDifferingValue(params.value, row);  
//                 return (<div className={cellClass}>{params.value}</div>);   
//             },  
//             renderHeader: renderHeader  
//         }))  
//     ];  
  
//     return (  
//         <div className="data-grid-container">  
//             <DataGrid  
//                 rows={transformedData}
//                 columns={columns}  
//                 disableAdvanceFilters  
//                 disableToolbarFilter  
//                 // disableRowSelectionOnClick  
//                 pagination={false}
//             />  
//         </div>  
//     );  
// };  
  
// export default DataGridDemo;  
// import { DataGrid } from '@optum-osgp-temp/osgp-ui-composite-comp-lib';
// import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
// import './DataGrid.css';
// import { extractFields, transformData } from '../Utility/reUsable';

// const DataGridDemo = ({ data }) => {
//     if (data.length === 0) {
//         return <div></div>;
//     }

//     const fields = extractFields(data);
//     const transformedData = transformData(fields);
//     console.log(fields);

//     const renderHeader = (params) => (
//         <div className="headerCellContent">
//             <strong>{params.colDef.headerName}</strong>
//         </div>
//     );

//     const columns = Object.keys(transformedData[0])
//     .filter(key => key !== 'id')
//     .map(key => ({
//         field: key,
//         headerName: key.toUpperCase(),
//         width: 150,
//         renderHeader: renderHeader
//     }));
//     function CustomToolbar() {
//         return (
//           <GridToolbarContainer>
//             <GridToolbarExport />
//           </GridToolbarContainer>
//         );
//       }

//     return (
        
        // <div className="datagrid" style={{ height: 400, width: '100%' }}>
        //     <DataGrid rows={transformedData} columns={columns} pageSize={5} slots={{ toolbar: CustomToolbar }}/>
        // </div>
//     );
// };

// export default DataGridDemo;
