import React, { useState } from 'react';  
import { Button } from '@optum-osgp-temp/osgp-ui-component-lib';  
import { LoadingIndicator } from "@optum-osgp-temp/osgp-ui-component-lib";  
import { Worker, Viewer } from '@react-pdf-viewer/core';  
import '@react-pdf-viewer/core/lib/styles/index.css';  
import '@react-pdf-viewer/default-layout/lib/styles/index.css';  
import { Tabs, Tab, Box, Toolbar, IconButton } from '@mui/material';  
import ZoomInIcon from '@mui/icons-material/ZoomIn';  
import ZoomOutIcon from '@mui/icons-material/ZoomOut';  
import { pdfjs } from "react-pdf";  
import './pdfViewer.css';  
import { onFileChange, handleUpload, handleTabChange, TabPanel, handleZoomIn, handleZoomOut } from '../Utility/multiplePdfInput';  
  
const MultipleFilesInput = ({ setApiData }) => {  
    const [files, setFiles] = useState([]);  
    const [uploadedFiles, setUploadedFiles] = useState([]);  
    const [selectedTab, setSelectedTab] = useState(0);  
    const [zoomLevel, setZoomLevel] = useState(1);  
    const [isLoading, setIsLoading] = useState(false);  
  
    return (  
        <div className="multiple-files-input-container">  
            <div className="input-container">  
                <input type="file" multiple onChange={(e) => onFileChange(e, setFiles)} className="inputFile uitkBtnBoldGhost" data-testid="inputFile" />  
                <Button onPress={() => handleUpload(files, setIsLoading, setUploadedFiles, setFiles, setApiData)} variant='ghost' className="button" marginTop="5px">  
                    Upload  
                </Button>  
            </div>  
            {isLoading ? (  
                <LoadingIndicator size={"m"} loading={true} className="loader" />  
            ) : (  
                <div>  
                    {uploadedFiles.length > 0 && (  
                        <div>  
                            <Toolbar className="toolbar">  
                                <Tabs  
                                    value={selectedTab}  
                                    onChange={(e, newValue) => handleTabChange(e, newValue, setSelectedTab)}  
                                    variant="scrollable"  
                                    scrollButtons="auto"  
                                    sx={{ flexGrow: 1 }} /* Ensure Tabs take available space */  
                                >  
                                    {uploadedFiles.map((file, index) => (  
                                        <Tab key={index} label={file.name} />  
                                    ))}  
                                </Tabs>  
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>  
                                    <IconButton onPress={() => handleZoomIn(setZoomLevel)}>  
                                        <ZoomInIcon />  
                                    </IconButton>  
                                    <IconButton onPress={() => handleZoomOut(setZoomLevel)}>  
                                        <ZoomOutIcon />  
                                    </IconButton>  
                                </Box>  
                            </Toolbar>  
                            {uploadedFiles.map((file, index) => (  
                                <TabPanel value={selectedTab} index={index} key={index}>  
                                    <div className="pdf-viewer-container">  
                                        <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`}>  
                                            <Viewer fileUrl={URL.createObjectURL(file)} defaultScale={zoomLevel} />  
                                        </Worker>  
                                    </div>  
                                </TabPanel>  
                            ))}  
                        </div>  
                    )}  
                </div>  
            )}  
        </div>  
    );  
};  
export default MultipleFilesInput;  