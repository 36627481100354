import React, { useState } from 'react';  
import { GlobalNavExample } from "../components/NavigationBar";  
import MultipleFilesInput from '../components/MultipleFilesInput';  
import DataGridDemo from '../components/table'; 
import EmptyDataGrid from '../components/emptyDatagrid'; 
import '../App.css';  
  
function TestPage() {  
    const [apiData, setApiData] = useState([]);  
  
    return (  
        <div style={{ marginBottom: '5px' }}>  
            <GlobalNavExample />  
            <div className='page' style={{ 'overflow': 'auto' }}>  
                <div className='pdf'>  
                    <MultipleFilesInput setApiData={setApiData} />  
                </div>  
                <div className='model'>  
                {apiData.length === 0 ? <EmptyDataGrid /> : <DataGridDemo data={apiData} />} 
                </div>  
            </div>  
        </div>  
    );  
}  
  
export default TestPage;  
