import React from "react";
import { TextInput,Label,Button } from '@optum-osgp-temp/osgp-ui-component-lib';
import "./Login.css"
import { useState } from "react";
import {loginInformation} from "../Utility/reUsable.jsx";
import img1 from '../assets/image1.png';
import {  useNavigate } from 'react-router-dom'; 
 
const Login = ({ setIsVerified })=> {
    const [userName,setUserName]=useState('');
    const [password,setPassword]=useState('');
    const navigate = useNavigate();
   
    function handleuserNameChange(event) {
        setUserName(event.target.value);    
      }
     
       function handlepasswordChange(event) {
        setPassword(event.target.value);    
      }
   
    function handleLogin(){
        loginInformation(userName, password,setIsVerified)
        .then(() => {  
        navigate('/UnorganizedForms',{ state: { userName } });  
        }) 
    }
   
    return (
        <div >
            <div style={{display:"flex"}}>
                <div style={{backgroundColor:"white",width:"50%",paddingBottom:"20%",maxHeight:"fit-content"}} >
                   <div className = "container">
                   <div className="insight-text-container">  
                        <p className="insight-text">  
                        SGS DIP is an <span className="highlight">"Autonomous"</span> solution working with a vision to eliminate or minimize human reviews to achieve <span className="highlight">100x benefits for CMS and HHS renewal forms.</span> 
                        </p></div> 
                        <img className="imgLogin" src={img1} alt="" />
                    </div>
                </div>
                <div style={{backgroundColor:"#fff",width:"50%",height:"100%"}}>
                    <div className = "containerLogin">
                    <div className = "header">
                    <div className = "text" data-testid='Login'>Login</div>
                </div>
                <div className = "inputs">
                    <div className = "inputForm">
                        <Label style={{marginLeft:"5px",marginRight:"2px",fontSize:"small"}}> Username </Label>
                        <TextInput type ='text' name="UserID" value={userName} onChange={handleuserNameChange}/>
                    </div>
                    <div className = "inputForm">
                        <Label style={{marginLeft:"7px",marginRight:"5px",fontSize:"small"}}> Password </Label>
                        <TextInput type = 'password' value={password} onChange={handlepasswordChange}/>
                    </div>
                </div>
                <div className = "submit-container">
                    <Button variant="ghost" onPress = {handleLogin} className="submit">Submit</Button>
                </div>
            </div>
        </div>
        </div>
    </div>
    );
}
export default Login 