// import React from "react";
// import { Header } from "@optum-osgp-temp/osgp-ui-component-lib";
// import './DataGrid.css';
// import { useNavigate } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';   

// const defaultLogo = (
//   <>
//     <div style={{ display:'flex', flexDirection:'row', position:'relative',height:'15px',top: 'auto' }}>
//       <div className="Logo_container" style={{ display: 'flex', alignItems: 'center', }}>
//         <img
//           src={require('../assets/optum.png')}
//           alt="logo"
//         />
//       </div>
//       <div className="productName_container">
//         <h3 style={{ margin:'0rem 0rem 0 1rem', color: 'grey' }}>Document Intelligence Platform</h3>
//         {/* <h4 style={{margin:'-3rem 0rem 0rem 43rem'}}>Reviewer Portal</h4> */}
//       </div>
//     </div>
//   </>
// );

// export const GlobalNavExample = ({ logoContent = defaultLogo }) => { 
  
// const navigate = useNavigate();
// const location = useLocation();  
// const userName = location.state ? location.state.userName : '';  

// function onClickProfileMenu(label) {    
//   const paths = {  
//     Home: { path: '/Home', state: { userName } },  
//     Train: { path: '/Train', state: { userName } },  
//     Demo: { path: '/Demo', state: { userName } },  
//     Logout: { path: '/' }, 
//   }; 
     
//   if (paths[label]) {  
//     navigate(paths[label].path, { state: paths[label].state });  
//   } else {  
//     console.warn('No path found for this label', label);  
//   }  
// }
   
// const userProfileProps = { 
//   profileName:userName,
//   onClickProfileEvent: onClickProfileMenu,
//   roles:
//     [
//       {
//         label:"Home",
//       },
//       {
//         label:"Train",
//       },
//       {
//         label:"Demo",
//       },
//       {
//         label:"Logout",
//       },
//     ],
// }

//   return (  
//     <Header  
//       productName=""  
//       productLink=""  
//       logoContent={logoContent}  
//       aria-label="navigation"  
//       userProfileProps={userProfileProps}  
//     />  
//   );  
// };

// export default GlobalNavExample

import React from "react";
import { Header } from "@optum-osgp-temp/osgp-ui-component-lib";
import './DataGrid.css';
import { useNavigate, useLocation } from 'react-router-dom';

const defaultLogo = (
  <>
    <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', height: '15px', top: 'auto' }}>
      <div className="Logo_container" style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={require('../assets/optum.png')}
          alt="logo"
        />
      </div>
      <div className="productName_container">
        <h3 style={{ margin: '0rem 0rem 0 1rem', color: 'grey' }}>Document Intelligence Platform</h3>
      </div>
    </div>
  </>
);

export const GlobalNavExample = ({ logoContent = defaultLogo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userName = location.state ? location.state.userName : '';

  function onClickProfileMenu(label) {
    const paths = {
      'Organized Forms': { path: '/OrganizedForms', state: { userName } },
      'Unorganized Forms': { path: '/UnorganizedForms', state: { userName } },
      'Logout': { path: '/' },
    };

    if (paths[label]) {
      navigate(paths[label].path, { state: paths[label].state });
    } else {
      console.warn('No path found for this label', label);
    }
  }

  const currentPath = location.pathname.substring(1).toLowerCase().replace(/\s+/g, '');
  const userProfileProps = {
    profileName: userName,
    onClickProfileEvent: onClickProfileMenu,
    roles: [
      { label: 'Organized Forms' },
      // { label: 'Train' },
      { label: 'Unorganized Forms' },
      { label: 'Logout' },
    ].filter(role => role.label.toLowerCase().replace(/\s+/g, '') !== currentPath),
  };

  return (
    <Header
      productName=""
      productLink=""
      logoContent={logoContent}
      aria-label="navigation"
      userProfileProps={userProfileProps}
    />
  );
};

export default GlobalNavExample;
